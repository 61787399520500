@import "components/mixins.scss";

/////////////////////////////////////////////////////////////////////////////////////////
/*  "CORE" STYLES */

:root {
  --kit-color-primary: #dd0000;
  --kit-font-family: "Mukta", sans-serif;
}

html {
  font-size: 15px;
  @media (max-width: $sm-max-width) {
    font-size: 14px;
  }
}

body {
  font-size: 1rem;
  line-height: 1.5;
  font-family: $base-font-family;
  color: $text;
  overflow-x: hidden;
  position: relative;
  font-variant: normal;
  font-feature-settings: normal;
}

a {
  text-decoration: none;
  color: $text;
  @include transition-color();
  &:hover,
  &:active,
  &:focus {
    color: $blue;
    text-decoration: none;
  }
}

input {
  outline: none !important;
  font-family: $base-font-family;
  color: $text;
}

button,
input {
  box-shadow: none !important;
  outline: none !important;
}

input[type="text"],
input[type="password"],
input[type="email"],
textarea {
  appearance: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black;
}

svg {
  vertical-align: initial;
  overflow: auto;
}

.badge-example {
  font-size: rem(14);
  text-transform: uppercase;
  margin-bottom: rem(15);
  background: $gray-2;
  color: $black;
  display: inline-block;
  padding: rem(3) rem(6);
  border-radius: 4px;
}

// dark theme
[data-kit-theme="dark"] {
  body {
    background: $dark-gray-5;
    color: $dark-gray-1;
  }

  .badge-example {
    background: $dark-gray-4;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $dark-gray-1;
  }

  a {
    color: $dark-gray-1;
    &:hover,
    &:active,
    &:focus {
      color: $blue;
      text-decoration: none;
    }
  }
}

.ant-btn-primary {
  background: #0268d6 !important;
  border-color: #0268d6 !important;
  &:hover {
    background: #0268d6 !important;
    border-color: #0268d6 !important;
  }
}

.ant-input {
  color: #000000 !important;
}

.ant-form-item {
  color: #000000 !important;
}
.usercontainer {
  display: flex;

  @media screen and (max-width: $md-max-width) {
    display: block;
  }
}
.naph-font {
  color: #595c97;
  font-size: 14px;
}

.code-box {
  border: 1px solid #f0f0f0;
  color: rgba(0, 0, 0, 0.85);
  padding: 32px 20px 40px;
  margin-bottom: 6px;
}

.spin-container {
  margin: 40px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  //background: rgba(237, 134, 50, 0.05);
  border-radius: 4px;
}

.center-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-descriptions-item-label {
  font-weight: 900 !important;
}
[data-kit-theme="default"] {
  .ant-menu-item a {
    color: $black;

    &:hover {
      color: $gray-5;
    }
  }
  .ant-table-thead > tr > th {
    background-color: #de1a1a !important;
    color: white;
    font-weight: 900;
  }
}
